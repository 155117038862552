/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Grid,
  Stack,
  Radio,
  Select,
  Button,
  MenuItem,
  TextField,
  Typography,
  RadioGroup,
  InputLabel,
  FormControl,
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText
} from '@mui/material';
// import axios from 'axios';
import {
  callEndTime,
  timeZonWiseDateTime,
  createZoomMeetingUrl,
  showCharacterCountWarning
} from '../components/common';
import _ from 'lodash';
import uuid from 'react-uuid';
import { API } from '../../../api';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import MyTooltip from '../components/MyTooltip';
import { useSearchParams } from 'react-router-dom';
import Left from '../../../assets/images/Left.svg';
import Right from '../../../assets/images/Right.svg';
import { getChatUsers } from '../../../routes/helpers';
import './../../../assets/style/CallBookingCalendar.css';
import { getUserDetails } from '../../../utils/orgName';
import { timezones } from '../../profile-page/timeZones';
import { useXpPoints } from '../components/menteeXpPoints';
import { inputCharacterCount } from '../components/common';
import useCallDetails from '../../../queries/useCallDetails';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
// import { setCallRows } from '../../../redux/slices/call/callsReducer';
import { callsCountActions } from '../../../redux/slices/callCountSlice';
import CallBookingPopupLoader from '../../../Loader/CallBookingPopupLoader';
import { learningObjectivesData } from '../../../utils/learningObjectivesData';
import { useModule } from '../../../utils/useModule';

interface CallBookingCalendarProps {
  onClose: () => void; // Function to close the calendar
  channelNaame: string;
  chatType: string;
  threadParticipantsUsers: any[];
  pageName: string;
  bookedCallDetails: any;
  isCallEditing?: boolean;
  title?:any;
  objectiveDetails?: any; 
  onMeetingScheduled?: (meetingDetails: any) => void;
}

let chatThreadId = '';
let callBookedWith: string | null;
let participantscommunicationId: any[];

const CallBookingCalendar: React.FC<CallBookingCalendarProps> = ({
  title,
  onClose,
  channelNaame,
  threadParticipantsUsers,
  chatType,
  pageName,
  bookedCallDetails,
  isCallEditing = false,
  objectiveDetails,
 onMeetingScheduled
}) => {
  // State variables for selected date and time, invitees, and description
  const page = 1; // Set your desired page size
  const [pageSize, setPageSize] = useState(10);
  const userCallsData = useCallDetails(page, pageSize);
  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);
  const [selectedUtcDate, setSelectedUtcDate] = useState<any | null>(null);
  const [ParticipantUsers, setParticipantUsers] = useState<any[]>([]);
  const [matchingMembersData, setMatchingMembersData] = useState<any[]>([]);
  const [selectedMember, setSelectedMember] = useState<any | null>(null);
  const [selectedCommIds, setselectedCommIds] = useState<any[]>([]);
  const [timeInterval, setTimeInterval] = useState('30');
  const [callTitle, setCallTitle] = useState('');
  const [description, setDescription] = useState<string>('');
  const [privateNotes, setPrivateNotes] = useState<string>('');
  const [originalBookBy, setOriginalBookBy] = useState<string>('');
  const [originalBookWith, setOriginalBookWith] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const threadId = searchParams.get('threadid') || '';
  const { mentee } = useProfileDetails();
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);
  chatThreadId = threadId || '';
  const [calendarDefaultDate, setcalendarDefaultDate] = useState(new Date());
  const userData = useAppSelector((state) => state.userProfile.data);
  const fetchCommunityMembersData = useAppSelector((state) => state.acsCommunityUsers.data);
  const tokens = localStorage.getItem('tokens') ? JSON.parse(localStorage.getItem('tokens') || '') : {};
  const dispatch = useAppDispatch();
  const currentUsercommunicationId = userData?.personal_details?.communicationId;
  const timzoneCode = userData?.personal_details?.timezone ?? '';
  const currentUserTimeZone = getTimeZoneNameCodeByValue(userData?.personal_details?.timezone || '') ?? '';
  const getXpPoints = useXpPoints();
  const [showInviteeList, setShowInviteeList] = useState<boolean>(false);

  const dosenMeeting = 'dosenVirtualMeeting';
  const virtualMeeting = 'virtualMeeting';
  const inPersonEvent = 'inPersonEvent';
const { orgId } = getUserDetails();
const [learningObjectives, setLearningObjectives] = useState<{ id: string; objective: string }[]>([]);
const [selectedObjective, setSelectedObjective] = useState<{ id: string; objective: string } | null>(null);
const { isMentorship } = useModule();
const [objTitle, setObjTitle] = useState(title);

  /* * */
  useEffect(() => {
    if (!title) return;
    const foundObjective = typeof title === 'object' ? title : learningObjectives.find(obj => obj.objective === title);
    setSelectedObjective(foundObjective || null);
  }, [title, learningObjectives]);
  
  useEffect(() => {
    if (!bookedCallDetails) {
      setSelectedDateTime(new Date());

      if (chatType === 'Groups') {
        setOriginalBookBy('');
        setOriginalBookWith('');
        setMatchingMembersData([]);
        setParticipantUsers([]);
      } else {
        const matchingMembers = Array.isArray(threadParticipantsUsers)
        ? threadParticipantsUsers.filter(
          (member) => member?.id?.communicationUserId !== currentUsercommunicationId
        ):[];
        let communicationUserIds: any = [];

        if (pageName === 'Chat') {
          communicationUserIds = matchingMembers.map((user) => user?.id?.communicationUserId);
        } else if (pageName === 'Community') {
          communicationUserIds = matchingMembers.map((user) => user?.personal_details?.communicationId);
        } else if (['Mentee', 'Mentor'].includes(pageName)) {
          communicationUserIds = matchingMembers.map((user) => user?.communicationId);
        } else {
          communicationUserIds = matchingMembers.map((user) => user?.id?.communicationId);
        }

        setselectedCommIds(communicationUserIds[0]);
        const matchingMembersRow = fetchCommunityMembersData?.filter((member) =>
          communicationUserIds?.includes(member?.id?.communicationUserId)
        );

        if (matchingMembersRow.length > 0) {
          setOriginalBookBy(userData?.personal_details?.name);
          setOriginalBookWith(matchingMembersRow[0]?.displayName);
        }
        setParticipantUsers(communicationUserIds);
      }
    } else {
      if (bookedCallDetails?.meetingType === 'Dosen') {
        setVirtualLink('');
        setMeetingType(dosenMeeting);
      } else if (bookedCallDetails?.meetingType === 'InPerson') {
        setVirtualLink('');
        setMeetingType(inPersonEvent);
      } else {
        setVirtualLink(bookedCallDetails?.meetingLink || '');
        setMeetingType(virtualMeeting);
      }

      let filteredMidValues = [bookedCallDetails?.callBookedBy?.id];

      if (bookedCallDetails?.chatType === 'Chats') {
        filteredMidValues.push(bookedCallDetails?.callBookedWith?.id);
        setOriginalBookBy(bookedCallDetails?.callBookedBy?.displayName);
        setOriginalBookWith(bookedCallDetails?.callBookedWith?.displayName);

        const bookwithmembers = bookedCallDetails?.participantsUser.filter(
          (item: { mid: any }) => item.mid === bookedCallDetails?.callBookedWith?.id
        );

        const bookwithmembersComId = bookwithmembers.map((member: { communicationId: any }) => member.communicationId);
        setselectedCommIds(bookwithmembersComId);
      }

      const matchingMembers = bookedCallDetails?.participantsUser.filter(
        (item: { mid: any }) => !filteredMidValues.includes(item.mid)
      );

      const newCommunicationUserIds = matchingMembers.map((member: { communicationId: any }) => member.communicationId);
      const matchingMembersRow = fetchCommunityMembersData.filter((member) =>
        newCommunicationUserIds.includes(member.id.communicationUserId)
      );

      setMatchingMembersData(matchingMembersRow);
      setParticipantUsers(newCommunicationUserIds);
    }
  }, [
    bookedCallDetails,
    chatType,
    currentUsercommunicationId,
    fetchCommunityMembersData,
    pageName,
    threadParticipantsUsers,
    userData?.personal_details?.name
  ]);
  const loginUser = useAppSelector((state) => state.userProfile.data);
  const admins = _.get(loginUser, 'admins');
  const desiredCommunityMembers = useMemo(() => {
    const loginUserRole = localStorage.getItem('role');
    return getChatUsers(fetchCommunityMembersData, loginUserRole, matchingMembersData, admins);
  }, [admins, fetchCommunityMembersData, matchingMembersData]);

  /** Handle selected date and time change */
  const handleDateTimeChange = (date: Date | null) => {
    setSelectedTime('');
    setSelectedDateTime(date);
  };

  /**
   * Default call date selected
   */
  useEffect(() => {
    if (bookedCallDetails?.callDate) {
      const dateFormate = timeZonWiseDateTime(
        bookedCallDetails?.callDate?.toString(),
        bookedCallDetails?.callTime?.toString(),
        timzoneCode
      );
      const callDate = new Date(dateFormate);
      setSelectedDateTime(callDate);
      const utcDate = callDate.toISOString().split('T')[0]; // Extracts the date part
      setSelectedUtcDate(utcDate);
    }
    if (bookedCallDetails?.callTime) {
      setSelectedTime(bookedCallDetails?.callTime);
    }
    setDescription(bookedCallDetails?.description || '');
    if (bookedCallDetails) {
      setTimeInterval(bookedCallDetails?.callTimeInterval);
    }
    setCallTitle(bookedCallDetails?.callTitle || '');
  }, [bookedCallDetails]);

  /** Handle selected call length */
  const handleCallLength = (event: any) => {
    setTimeInterval(event.target.value);
  };

  /** Handle call title length */
  const handleCallTitle = (event: any) => {
    setCallTitle(event.target.value);
    showCharacterCountWarning(event.target.value, maxCharacterCountCallTitle);
  };

  /** Making selected ing for Print Top of the calendar and time slots */
  const formatMonthYear = (date: Date | null) => {
    const currentDate = new Date();
    const displayDate = date || currentDate;

    const options: Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric', weekday: 'long' };
    const monthYearWithWeekday = displayDate.toLocaleDateString(undefined, options);

    const [month, year, day] = monthYearWithWeekday.split(' ');
    const monthYear = `${month} ${year}`; //'September 2023'
    // const monthYearDay = `${month} ${year}, ${day}`; // 'September 2023, Thursday'
    const dayDate = `${day}, ${displayDate.getDate()}`; // 'Thursday, 21'
    return { monthYear, dayDate };
  };

  /** handle time slot change */
  const handleTimeSlotChange = (utcDateTimeString: string) => {
    if (utcDateTimeString) {
      const utcDateTime = new Date(utcDateTimeString);
      // Extract date and time components into different variables
      const utcDate = utcDateTime.toISOString().split('T')[0]; // Extracts the date part
      const utcTime = utcDateTime.toISOString().split('T')[1].split('.')[0].slice(0, 5); // Extract HH:mm
      setSelectedUtcDate(utcDate);
      setSelectedTime(utcTime);
    }
  };

  /** Handle selected invitees change */
  const handleInviteesChange = (event: React.ChangeEvent<{}>, value: any[]) => {
    setMatchingMembersData(value);
    let newParticipantUsers: any = [];
    newParticipantUsers = value?.map((user) => user?.id?.communicationUserId);
    setParticipantUsers(newParticipantUsers);
  };

  useEffect(() => {
    if (!bookedCallDetails) {
      setShowInviteeList(pageName !== 'Calendar' || !!selectedMember);
      setMatchingMembersData([]);
      setParticipantUsers([]);
    } else {
      setShowInviteeList(true);
    }
  }, [selectedMember, pageName]);

  /** Handle purpose of call change */
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    showCharacterCountWarning(newValue, maxCharacterCount);
    setDescription(newValue);
  };

  /** Handle PrivateNotes change */
  const handlePrivateNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    showCharacterCountWarning(newValue, maxCharacterCount);
    setPrivateNotes(newValue);
  };

  /** Field Character Count */
  const maxCharacterCount = 1500;
  const maxCharacterCountCallTitle = 100;
  const totalCharactersPrivateNotes = inputCharacterCount(description, maxCharacterCount);
  const totalCharactersDescription = inputCharacterCount(description, maxCharacterCount);
  const totalCharactersCallTitle = inputCharacterCount(callTitle, maxCharacterCountCallTitle);

  const validateLink = (url: string) => {
    const zoomRegex = /^https:\/\/(?:[\w-]+\.)*zoom\.us\/(?:j\/)?\d{9,11}|u\/[a-zA-Z0-9]+$/i;
    const teamsRegex = /^https:\/\/teams\.live\.com\/meet\/\d+(\?p=.+)?/i;
    const googleMeetRegex = /^https:\/\/meet\.google\.com\/[a-z0-9\-]+$/i;

    // Check if the URL matches any of the regex patterns
    if (zoomRegex.test(url) || teamsRegex.test(url) || googleMeetRegex.test(url)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  /** Handle the Confirm button click */

  const handleConfirm = () => {
    const participantUsersData = [...ParticipantUsers];
    if (bookedCallDetails) {
      let filteredMidValues: any[] = [];
      if (bookedCallDetails?.chatType === 'Chats') {
        filteredMidValues = [bookedCallDetails?.callBookedBy?.id, bookedCallDetails?.callBookedWith?.id];
      } else {
        filteredMidValues = [bookedCallDetails?.callBookedBy?.id];
      }
      const matchingMembers = bookedCallDetails?.participantsUser.filter((item: { mid: any }) =>
        filteredMidValues.includes(item.mid)
      );
      matchingMembers.forEach((member: { communicationId: any }) => {
        if (!participantUsersData.includes(member.communicationId)) {
          participantUsersData.push(member.communicationId);
        }
      });
    } else {
      const loginUserCommnicationId = currentUsercommunicationId;
      if (!participantUsersData.includes(loginUserCommnicationId)) {
        participantUsersData.push(loginUserCommnicationId);
      }
      if (pageName === 'Calendar') {
        participantUsersData.push(selectedMember?.id?.communicationId);
      }
      if (chatType === 'Groups') {
        const participantUsersComIds = threadParticipantsUsers.map((userRow) => userRow.id.communicationUserId);
        participantUsersComIds.forEach((member) => {
          if (!participantUsersData.includes(member)) {
            participantUsersData.push(member);
          }
        });
      }
    }

    if (callTitle.trim().length === 0) {
      toast.error('Please enter the title of meeting');
      return;
    }
    // Validate if Attendee is Selected
    if (pageName === 'Calendar' && !selectedMember) {
      toast.error('Please select an attendee');
      return;
    }

    // Validate if a date, time, and time slot are selected
    if (!selectedDateTime || !selectedTime) {
      toast.error('Please select a date and time');
      return;
    }

    if (meetingType === virtualMeeting && (!virtualLink || !isValid)) {
      setIsValid(false);
      return;
    }
    const formattedDate = selectedUtcDate;
    // console.log(formattedDate+ 'formattedDate' + selectedTime + 'selectedTime','soumen');
    // Make an API request using Axios
    const { orgId } = getUserDetails();
    const currentUserId = getUserDetails().userId;
    /**
     * Data for call booking api
     */
    let meetingId = uuid();
    let callFlag = 'insert';
    if (bookedCallDetails) {
      meetingId = bookedCallDetails?.meetingId;
      callFlag = 'edit';
    }
    const timeIntervalNumber = parseInt(timeInterval, 10);
    const meetingLink = createZoomMeetingUrl(meetingId, formattedDate, selectedTime, timeIntervalNumber, '');
    const CallEndTime = callEndTime(formattedDate, selectedTime, timeIntervalNumber);
    let type = 'Dosen';
    if (meetingType === inPersonEvent) {
      type = 'InPerson';
    } else if (virtualLink.includes('zoom')) {
      type = 'Zoom';
    } else if (virtualLink.includes('meet.google.com')) {
      type = 'Gmeet';
    } else if (virtualLink.includes('teams.live.com')) {
      type = 'Teams';
    }
    const requestBody: {
      meetingId: any;
      callDate: string;
      callTime: string;
      callFlag: string;
      callEndTime: number;
      callBookedWith?: string | null;
      callBookedBy: string | null | undefined;
      callTimeInterval: number;
      participantsUser: any[];
      chatThreadId?: string;
      chatType?: string;
      description?: string;
      meetingLink: string;
      callTitle?: string;
      meetingType: string;
      objective?: string
    } = {
      meetingId: meetingId,
      callDate: formattedDate,
      callTime: selectedTime,
      callFlag: callFlag,
      callEndTime: CallEndTime,
      callBookedBy: currentUsercommunicationId,
      callTimeInterval: timeIntervalNumber,
      participantsUser: participantUsersData,
      meetingLink:
        meetingType === virtualMeeting ? virtualLink.trim() : meetingType === inPersonEvent ? '' : meetingLink,
      meetingType: type,
      ...(isMentorship && selectedObjective?.id ? { objective: selectedObjective.id } : {}),
    };

    if (chatThreadId) {
      requestBody.chatThreadId = chatThreadId;
    }
    if (callTitle) {
      requestBody.callTitle = callTitle;
    }
    if (chatType) {
      requestBody.chatType = chatType;
    }

    if (chatType) {
      let bookwithUser: any = '';
      if (bookedCallDetails) {
        if (bookedCallDetails?.chatType === 'Groups') {
          const chatThreadId = bookedCallDetails?.chatThreadId;
          bookwithUser = chatThreadId;
        } else {
          const cllBookwithUserId = bookedCallDetails?.callBookedWith?.id;
          const matchedParticipant = bookedCallDetails?.participantsUser.find(
            (participant: { mid: any }) => participant?.mid === cllBookwithUserId
          );
          bookwithUser = matchedParticipant?.communicationId;
        }
      } else {
        if (pageName === 'Calendar') {
          bookwithUser = selectedMember?.id?.communicationId;
        } else {
          if (chatType === 'Groups') {
            bookwithUser = chatThreadId;
          } else {
            const matchingMembers =
            Array.isArray(threadParticipantsUsers)
            ? threadParticipantsUsers.filter(
                (member) => member?.id?.communicationUserId !== currentUsercommunicationId
              )
            : [];
            let communicationUserIds: any = '';
            if (pageName === 'Chat') {
              communicationUserIds = matchingMembers?.map((user) => user?.id?.communicationUserId);
            } else if (pageName === 'Community') {
              communicationUserIds = matchingMembers?.map((user) => user?.personal_details?.communicationId);
            } else if (['Mentee', 'Mentor'].includes(pageName)) {
              communicationUserIds = matchingMembers?.map((user) => user?.communicationId);
            } else {
              communicationUserIds = matchingMembers?.map(
                (user: { id: { communicationId: any } }) => user?.id?.communicationId
              );
            }
            if (communicationUserIds.length > 0) {
              bookwithUser = communicationUserIds[0];
            }
            if (!participantUsersData.includes(bookwithUser)) {
              participantUsersData.push(bookwithUser);
            }
          }
        }
      }
      callBookedWith = bookwithUser;
    }
    if (callBookedWith) {
      requestBody.callBookedWith = callBookedWith;
    }
    if (description) {
      requestBody.description = description;
    }
    setLoading(true);
    /**
     * Call the api
     */
    bookedTheCall(requestBody, orgId, getUserDetails().location, currentUserId);
  };

  const callsCount = useAppSelector((state) => state.callsCount.callsCount);

  /** Booked the call */
  const bookedTheCall = async (
    callData: {
      meetingId: any;
      callDate: string;
      callTime: string;
      callFlag: string;
      callEndTime: number;
      callBookedWith?: string | null | undefined;
      callBookedBy: string | null | undefined;
      callTimeInterval: number;
      participantsUser: any[];
      chatThreadId?: string | undefined;
      chatType?: string | undefined;
      description?: string | undefined;
      callTitle?: string;
      meetingLink: string;
      meetingType: string;
    },
    orgId: string,
    location: string,
    currentUserId: string
  ) => {
    try {
      const response = await API.createCall(callData, orgId, location, currentUserId);
      if (response.status === 200 && response.statusText === 'OK') {
        // refetch .....
        await userCallsData
          .invalidateQuery()
          .then((res) => { })
          .catch((err) => { })
          .finally(() => {
            userCallsData.refetch();
          });
        if (!isCallEditing) {
          dispatch(callsCountActions.updateCount(callsCount + 1));
        }
        if (onMeetingScheduled) {
          onMeetingScheduled({
            ...callData,
            response: response.data 
          });
        }
        setLoading(false);
        onClose();
        setSelectedDateTime(null);
        setOriginalBookBy('');
        setOriginalBookWith('');
        setMatchingMembersData([]);
        setParticipantUsers([]);
        setDescription('');
        // dispatch(setCallRows(_.get(response, 'data.getScheduleCallResponse', [])));
        toast.success('Meeting booked successfully');
        if (selectedGroup?.role === 'mentee') {
          getXpPoints('mentee');
        }
      }
    } catch (e) {
      setLoading(false);
      onClose();
      setSelectedDateTime(null);
      setOriginalBookBy('');
      setOriginalBookWith('');
      setMatchingMembersData([]);
      setParticipantUsers([]);
      setDescription('');
      toast.error('Something went wrong, please try again');
    }
  };

  /** Handle cancel */
  const handleCancel = () => {
    setSelectedDateTime(null);
    setOriginalBookBy('');
    setOriginalBookWith('');
    setMatchingMembersData([]);
    setParticipantUsers([]);
    setDescription('');
    onClose();
  };

  /** Handle month change */
  const handleMonthChange = (action: 'prev' | 'next') => {
    setSelectedDateTime((prevDate) => {
      const currentDate = new Date();
      const newDate = prevDate ? new Date(prevDate) : new Date();

      if (action === 'prev') {
        newDate.setMonth(newDate.getMonth() - 1);
      } else if (action === 'next') {
        // Check if the current date is within the 1st to 31st range
        if (newDate.getDate() >= 1 && newDate.getDate() <= 31) {
          // Always transition to the 1st day of the next month
          newDate.setDate(1);
          newDate.setMonth(newDate.getMonth() + 1);
        } else {
          // Check if newDate is the last day of the month
          const lastDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();

          if (newDate.getDate() === lastDayOfMonth) {
            // If it's the last day, set to the 1st day of the next month
            newDate.setDate(1);
            newDate.setMonth(newDate.getMonth() + 1);
          } else {
            // Otherwise, simply increment the month
            newDate.setMonth(newDate.getMonth() + 1);
          }
        }
      }
      return newDate < currentDate ? currentDate : newDate;
    });
  };

  function customStartCase(input: string) {
    const words = input.split(' ');
    const result = words.map((word: any) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return result.join(' ');
  }

  /** Get timeZoneNameCode */
  function getTimeZoneNameCodeByValue(value: string): string | undefined {
    const timezone = timezones.find((zone) => zone.value === value);
    return timezone ? timezone.timeZoneNameCode : '';
  }

  /** Get time slots */
  const getTimeSlots = () => {
    if (timeInterval) {
      if (selectedDateTime) {
        const timezone = currentUserTimeZone || '';
        const selectedDate = moment.tz(selectedDateTime, timezone).startOf('day');
        const endTime = moment(selectedDate).endOf('day');
        // added 30 here for fixed 30 minutes interval
        const timeIntervals = 30;

        const timeSlots = [];
        let currentTimeSlot = selectedDate.clone().startOf('hour');

        while (currentTimeSlot.isSameOrBefore(endTime)) {
          if (currentTimeSlot.isAfter(moment().tz(timezone))) {
            const currentTimeZoneSlot = currentTimeSlot.format('h:mm a');
            const utcTimeZoneSlot = moment.tz(currentTimeSlot, 'UTC').format('HH:mm');

            const currentTimezoneAbbreviation = moment.tz(timezone).format('z');
            const timeSlotWithTimeZone = `${currentTimeZoneSlot} (${currentTimezoneAbbreviation})`;

            const timeZoneWiseTimeSlot = {
              currentTimeZoneSlot: timeSlotWithTimeZone,
              utcTimeZoneSlot: utcTimeZoneSlot,
              utcDateTime: currentTimeSlot.toISOString() // Add UTC DateTime
            };

            timeSlots.push(timeZoneWiseTimeSlot);
          }
          currentTimeSlot = currentTimeSlot.add(timeIntervals, 'minutes');
        }

        return (
          <>
            {timeSlots?.map((timeSlot) => (
              <div key={timeSlot.currentTimeZoneSlot}>
                <input
                  type="radio"
                  name="timeSlot"
                  value={timeSlot.utcTimeZoneSlot}
                  checked={selectedTime === timeSlot.utcTimeZoneSlot}
                  onChange={() => handleTimeSlotChange(timeSlot.utcDateTime)} // Pass UTC DateTime
                  className="slotsCheckBtn"
                  id={timeSlot.utcTimeZoneSlot}
                />
                <label
                  htmlFor={timeSlot.utcTimeZoneSlot}
                  className={`checkedBoxs ${selectedTime === timeSlot.utcTimeZoneSlot ? 'Active' : ''}`}
                >
                  {timeSlot.currentTimeZoneSlot}
                </label>
              </div>
            ))}
          </>
        );
      }
    }
    return null;
  };

  /** Meeting Type  */
  const [meetingType, setMeetingType] = useState(dosenMeeting);
  const [virtualLink, setVirtualLink] = useState('');

  const handleMeetingTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeetingType(event.target.value);
  };

  const [isValid, setIsValid] = useState(true);

  const handleMeetingLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVirtualLink(event.target.value);
    validateLink(event.target.value.trim());
  };


  const fetchUserLearningObjectives = async (orgId: string) => {
    try {
      const response = await API.UserLearningObjectives({orgId});
      return Array.isArray(response.data?.data) ? response.data?.data : []; 
    } catch (error) {
      console.error("Error fetching learning objectives:", error);
      return [];
    }
  };
  useEffect(() => {
    const loadLearningObjectives = async () => {
      const data = await fetchUserLearningObjectives(orgId);
      setLearningObjectives(data);
      if (isCallEditing && bookedCallDetails?.objective) {
        const matchedObjective = data.find((obj:any) => obj.id === bookedCallDetails.objective);
        if (matchedObjective) {
          setSelectedObjective(matchedObjective);
        }
      }
      else if (isCallEditing && objectiveDetails) {
        setSelectedObjective(objectiveDetails);
      }
    };
    loadLearningObjectives();
  }, [orgId]);
  return (
    <>
      <DialogContent>
        {loading ? (
          <CallBookingPopupLoader />
        ) : (
          <>
            {/* Meeting agenda or details */}
            {/* <Typography variant="h5" mb={2}>
              Meeting agenda or details:
            </Typography> */}
            <Grid container spacing={2} mb={2}>
              {/* What do you want to do ? */}
              {/* <Grid item xs={12}>
                <Typography>What do you want to do?</Typography>         
                <FormControl>
                  <RadioGroup row name="meetingType">
                    <Box display="flex" alignItems="center" pr={2}>
                      <FormControlLabel value="webinar" control={<Radio />} label="" className="mr-0" />
                      <Typography variant='body1' color="primary">Webinar</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <FormControlLabel value="meeting" control={<Radio />} label="" className="mr-0" />
                      <Typography variant='body1' color="primary">Meeting</Typography>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid> */}

              {/* Title of Meeting */}
              <Grid item xs={12} className="pt-10">
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>
                  <InputLabel>Title of meeting *</InputLabel>
                  <InputLabel>{`${totalCharactersCallTitle?.textValueLength}/${totalCharactersCallTitle?.maxCharacterCount}`}</InputLabel>
                </Stack>
                <TextField
                  id="callTitle"
                  value={callTitle}
                  InputProps={{
                    inputProps: {
                      maxLength: totalCharactersCallTitle?.maxCharacterCount
                    }
                  }}
                  onChange={handleCallTitle}
                  name="callTitle"
                  placeholder="Title of meeting"
                />
              </Grid>

              {/* Learning Objectives */}
              {isMentorship && (
              <Grid item xs={12}>
                <InputLabel>Is this meeting about one of your learning objectives?</InputLabel>
                <Autocomplete
                  options={learningObjectives}
                  getOptionLabel={(option) => option.objective }
                  value={selectedObjective || objTitle|| objectiveDetails?.objective}
                  onChange={(event, newValue) => setSelectedObjective(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                    />
                  )}
                  fullWidth
                  disabled={!!objTitle|| !!objectiveDetails?.objective}
                />
              </Grid>
              )}
              {/* Reason for booking */}
              {/* <Grid item xs={12}>
                <InputLabel>Reason for booking *</InputLabel>
                <Select name="" required placeholder="Select">
                  <MenuItem value="">Select</MenuItem>
                </Select>
              </Grid> */}

              {/* Attendees for Calendar page */}
              {pageName === 'Calendar' && (
                <Grid item xs={12}>
                  <InputLabel>Attendees *</InputLabel>
                  <Autocomplete
                    autoHighlight
                    id="addOtherUser"
                    options={desiredCommunityMembers}
                    getOptionLabel={(option) => (option ? option.displayName : '')}
                    value={selectedMember}
                    isOptionEqualToValue={(option, value) =>
                      _.get(option, 'id.communicationUserId') === _.get(value, 'id.communicationUserId')
                    }
                    renderOption={(props, option) => {
                      if (option && !ParticipantUsers?.includes(option?.id?.communicationId)) {
                        return (
                          <li {...props} key={_.get(option, 'id.communicationUserId', '')}>
                            {customStartCase(_.get(option, 'displayName'))}
                          </li>
                        );
                      }
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Select" />}
                    onChange={(event, newValue) => {
                      setSelectedMember(newValue);
                      //handleAttendeesChange(event, [newValue]);
                    }}
                  />
                </Grid>
              )}

              {/* Attendees */}
              {chatType === 'Chats' && originalBookBy && originalBookWith && (
                <Grid item xs={12}>
                  <InputLabel>Attendees</InputLabel>
                  <TextField value={`${originalBookBy} & ${originalBookWith}`} disabled />
                </Grid>
              )}

              {/* Observers */}
              {/* <Grid item xs={12}>
                <InputLabel>Observers *</InputLabel>
                <Select name="" required placeholder="Select">
                  <MenuItem value="">Select</MenuItem>
                </Select>
              </Grid> */}

              {/* Length of the meeting */}
              <Grid item xs={12}>
                <InputLabel>Length of the meeting *</InputLabel>
                <Select
                  name="callLength"
                  required
                  value={timeInterval}
                  id="callLength"
                  onChange={handleCallLength}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="30">30 mins</MenuItem>
                  <MenuItem value="45">45 mins</MenuItem>
                  <MenuItem value="60">1 hour</MenuItem>
                  <MenuItem value="90">90 mins</MenuItem>
                  <MenuItem value="120">2 hours</MenuItem>
                  <MenuItem value="180">3 hours</MenuItem>
                </Select>
              </Grid>
            </Grid>

            {/* Calendar section */}
            <div className="availability-calendar-container">
              <div className="calendar-wrapper">
                <div className="date-Icon-Div">
                  <Typography variant="h5">{formatMonthYear(selectedDateTime).monthYear}</Typography>
                  <div>
                    <img
                      src={Left}
                      className="LeftRightIcon"
                      alt="celendericon"
                      onClick={() => handleMonthChange('prev')}
                    />
                    <img
                      src={Right}
                      className="LeftRightIcon"
                      alt="celendericon"
                      onClick={() => handleMonthChange('next')}
                    />
                  </div>
                </div>

                {/* Date picker component */}
                <Box pr={2} pb={2}>
                  <DatePicker
                    selected={selectedDateTime}
                    onChange={(date) => handleDateTimeChange(date)}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    inline
                    minDate={calendarDefaultDate}
                    className="custom-datepicker"
                    calendarClassName="custom-calendar"
                  />
                </Box>
              </div>
              {/* Time slots */}
              <div className="time-slots">
                <Box p={2}>
                  <Typography variant="h5" textAlign="center">
                    {formatMonthYear(selectedDateTime).dayDate}
                  </Typography>
                </Box>
                <div className="time-slots-container">{getTimeSlots()}</div>
              </div>
            </div>

            {/* Description */}
            <Grid container my={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>
                  <InputLabel>Purpose of meeting</InputLabel>
                  <InputLabel>{`${totalCharactersDescription?.textValueLength}/${totalCharactersDescription?.maxCharacterCount}`}</InputLabel>
                </Stack>
                <TextField
                  multiline
                  minRows={3}
                  InputProps={{
                    inputProps: {
                      maxLength: totalCharactersDescription?.maxCharacterCount
                    }
                  }}
                  onChange={handleDescriptionChange}
                  value={description}
                  id="description"
                  name="description"
                  placeholder="What do you want to discuss on the meeting?"
                />
              </Grid>
            </Grid>

            {/* Meeting Host */}
            <Grid item xs={12} className="mb-20">
              <Typography>Meeting Host</Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="meetingHostLabel"
                  id="meetingTypeRadioGroup"
                  name="meetingTypeRadioGroup"
                  value={meetingType}
                  onChange={handleMeetingTypeChange}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel value={dosenMeeting} control={<Radio />} label="" className="mr-0" />
                        <Typography variant="body1" color="primary">
                          Dosen Virtual Meetings
                          <MyTooltip
                            gapleft={0.5}
                            iconColor="#152536"
                            iconSize="16px"
                            title="Avail of Dosen’s Virtual Meeting for meetings of up to 4 people."
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={meetingType === virtualMeeting ? 5 : 'auto'}>
                        <Box display="flex" alignItems="center">
                          <FormControlLabel value={virtualMeeting} control={<Radio />} label="" className="mr-0" />
                          <Typography variant="body1" color="primary">
                            3rd Party Meeting Link
                            <MyTooltip
                              gapleft={0.5}
                              iconColor="#152536"
                              iconSize="16px"
                              title="Embed your own meeting links here so that your invitees receive automated Reminder Notifications and can join directly from their Dosen Calendar. Recommended for meetings with 5 people or more."
                            />
                          </Typography>
                        </Box>
                      </Grid>
                      {meetingType === virtualMeeting && (
                        <Grid item xs={12} sm={7} pl={1}>
                          <TextField
                            id="meetingLinkInput"
                            name="meetingLinkInput"
                            placeholder="Copy paste url here.."
                            value={virtualLink}
                            onChange={handleMeetingLinkChange}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {!isValid && meetingType === virtualMeeting && (
                      <FormHelperText error>Please provide a valid url</FormHelperText>
                    )}

                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel value={inPersonEvent} control={<Radio />} label="" className="mr-0" />
                        <Typography variant="body1" color="primary">
                          In-Person Event
                          <MyTooltip
                            gapleft={0.5}
                            iconColor="#152536"
                            iconSize="16px"
                            title="Inform members of In-Person events here and avail of automated Reminder Notifications directly from Dosen."
                          />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Attendee */}
            {showInviteeList && (
              <Grid item xs={12}>
                <InputLabel>Add other members to the meeting</InputLabel>
                <Autocomplete
                  multiple
                  id="addOtherUsers"
                  options={desiredCommunityMembers}
                  getOptionLabel={(option) => (option ? option.displayName : '')}
                  value={matchingMembersData}
                  isOptionEqualToValue={(option, value) =>
                    _.get(option, 'id.communicationUserId') === _.get(value, 'id.communicationUserId')
                  }
                  renderOption={(props, option) => {
                    if (pageName === 'Calendar') {
                      if (option && !selectedMember?.id?.communicationId.includes(option?.id?.communicationId)) {
                        // exclude self in invitee list
                        return (
                          <li {...props} key={_.get(option, 'id.communicationUserId', '')}>
                            {customStartCase(_.get(option, 'displayName'))}
                          </li>
                        );
                      }
                    } else {
                      if (option && !selectedCommIds?.includes(option?.id?.communicationId)) {
                        // exclude self in invitee list
                        return (
                          <li {...props} key={_.get(option, 'id.communicationUserId', '')}>
                            {customStartCase(_.get(option, 'displayName'))}
                          </li>
                        );
                      }
                    }
                  }}
                  renderInput={(params) => <TextField {...params} placeholder="Select" />}
                  onChange={handleInviteesChange}
                />
              </Grid>
            )}

            {/* Private Notes */}
            {/* {pageName === 'Calendar' && (
              <Grid container my={2}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0}>
                    <InputLabel>Private Notes</InputLabel>
                    <InputLabel>{`${totalCharactersPrivateNotes?.textValueLength}/${totalCharactersPrivateNotes?.maxCharacterCount}`}</InputLabel>
                  </Stack>
                  <TextField
                    multiline
                    minRows={3}
                    InputProps={{
                      inputProps: {
                        maxLength: totalCharactersPrivateNotes?.maxCharacterCount
                      }
                    }}
                    onChange={handlePrivateNotesChange}
                    value={privateNotes}
                    id="privateNotes"
                    name="privateNotes"
                    placeholder="Store your private notes here"
                  />
                </Grid>
              </Grid>
            )} */}

            <Box mt={2}>
              <Typography fontStyle="italic">
                Once you click the confirm button your meeting will be scheduled with everyone you invited
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button fullWidth color="secondary" variant="outlined" disabled={loading} onClick={handleCancel}>
          Cancel
        </Button>
        <Button fullWidth color="primary" disabled={loading} variant="contained" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

export default CallBookingCalendar;
