/* eslint-disable guard-for-in */
/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';
import {
  Box,
  Grid,
  Switch,
  Divider,
  Checkbox,
  TextField,
  InputLabel,
  Typography,
  FormHelperText,
  FormControlLabel
} from '@mui/material';
import { useForm, Controller, FieldValues, SubmitHandler } from 'react-hook-form';
import Fields from './Fields';
import { Members } from './Members';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import {
  lockIcon,
  topicArray,
  industryArray,
  imageSizeLimit,
  handleImageCompression,
  GroupBasedRoleFormatter
} from '../../../../../components/common';
import { API } from '../../../../../../../api';
import ROLES from '../../../../../../../utils/roles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLoader } from '../../../../../components/useLoader';
import { CommonStyle } from '../../../../../../../utils/muiStyle';
import { getUserDetails } from '../../../../../../../utils/orgName';
import useProfileDetails from '../../../../../../../hooks/useProfileDetails';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import BasicSettingsLoader from '../../../../../../../Loader/BasicSettingsLoader';
import { groupActions } from '../../../../../../../redux/slices/getAllGroups/getAllGroupsSlice';
import { breadCrumbsActions } from '../../../../../../../redux/slices/breadcrumbs/breadcrumbsSlice';
import { ORG_TYPES } from '../../../../../../../utils/constants';
import useGroupDetails from '../../../../../../../queries/useGroupDetails';
import MasterclassModelSetting from './MasterclassModelSetting';
import SubjectSetting from './SubjectSetting';
import LearningObjectsSetting from './LearningObjectivesSetting';
import LogoSetting from './LogoSetting';
import { useModule } from '../../../../../../../utils/useModule';

const imgSize = imageSizeLimit();

interface LearningObjectivesData {
  //display: boolean;
  number: number;
  objectives: string[];
}

/** optional Fields Array */
const initialValues = [
  {
    label: 'Profile photo',
    name: 'headshot',
    mentor: true,
    mentee: true,
    admin: true,
    mandatory: false
  },
  {
    label: 'Subject',
    name: 'subjects',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Gender',
    name: 'gender',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Ethnicity',
    name: 'ethnicity',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'First generation student?',
    name: 'firstGenerationStudent',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Disability type',
    name: 'disabilityType',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'DOB',
    name: 'dob',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Country',
    name: 'country',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Video intro',
    name: 'videoIntro',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Work History',
    name: 'workHistory',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Education History',
    name: 'educationHistory',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Hobbies & Interests',
    name: 'hobbiesInterests',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Socials',
    name: 'socials',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Learning Objectives',
    name: 'learningObjectives',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Company Name',
    name: 'companyName',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Company Information',
    name: 'companyInfo',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  }
  // {
  //   label: 'Topics',
  //   name: 'topics',
  //   mentor: false,
  //   mentee: false,
  //   admin: false,
  //   mandatory: false
  // }
];

interface NewItem {
  value: string;
  type: 'topic' | 'industry';
}

const BasicSettings = () => {
  const styles = CommonStyle({});
  const dispatch = useAppDispatch();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const orgType = useAppSelector((state) => state.orgDetails.data?.orgType) || '';
  // Extra topics and industries for Dev Bank Org Banks

  // UI is getting hanged if we use topicArray and industryArray like below, so defined them out of the component
  const isNotDevBank = orgType === ORG_TYPES.govt ? true : false;

  //let topics: any[] = topicArray(!isNotDevBank);
  //let industries: any[] = industryArray(!isNotDevBank);
  const [topics, setTopics] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [learningObjectivesData, setLearningObjectivesData] = useState<LearningObjectivesData>({
    // display: false,
    number: 1,
    objectives: []
  });

  const getIndustriess = async () => {
    const res = await API.getIndustriesAndTopics({ grpId, orgId, key: 'industries' });
    console.log(res, 'sssss');
    setIndustries(res?.data?.data);
  };

  const getTopics = async () => {
    const res = await API.getIndustriesAndTopics({ grpId, orgId, key: 'topics' });
    console.log(res, 'sssss');
    setTopics(res?.data?.data);
  };

  useEffect(() => {
    getIndustriess();
    getTopics();
  }, []);

  const grpId = useParams().id || '';
  const orgId = useParams().orgId || '';
  const mentorRoleTextLowerCase = GroupBasedRoleFormatter('mentor', 'mentor');
  const menteeRoleTextLowerCase = GroupBasedRoleFormatter('mentee', 'mentee');
  const [fieldValues, setFieldValues] = useState(initialValues);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);
  const [optional, setOptional] = useState<boolean>(false);
  const [imageUploaded, setImageUploaded] = useState<any>(false);
  const [tempImage, setTempImage] = useState<any>(null);
  const [matchUpdateFlag, setMatchUpdateFlag] = useState(false);
  const [basicUpdateFlag, setBasicUpdateFlag] = useState(false);
  const [industryUpdateFlag, setIndustryUpdateFlag] = useState(false);
  const [optionalFieldsUpdateFlag, setOptionalFieldsUpdateFlag] = useState(false);
  const [topicsUpdateFlag, setTopicsUpdateFlag] = useState(false);
  const [learningObjectiveUpdateFlag, setLearningObjectiveUpdateFlag] = useState(false);
  const [groupDisplayRoles, setGroupDisplayRoles] = useState<any>();
  const [inputFields, setInputFields] = useState<any>({
    nam: false,
    end: false,
    logo: false,
    matchPerMentee: false,
    matchPerMentor: false,
    sessionsPerMatch: false
  });
  const { nam, end, logo, matchPerMentee, matchPerMentor, sessionsPerMatch } = inputFields;
  const groupData = useAppSelector((state) => state.groupDetails);
  //console.log(groupData, 'groupData');
  const role = localStorage.getItem('role') || '';
  let locValue = getUserDetails().location;
  if (role === ROLES.platAdmin) {
    locValue = searchParams.get('loc') || '';
  }

  const initialCommunityData = {
    mentor: true,
    mentee: true,
    admin: true
  };

  const [selectedTopicsValue, setSelectedTopicsValue] = useState<any>([]);
  const [selectedIndustriesValue, setSelectedIndustriesValue] = useState<any>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const [includeValue, setIncludeValue] = React.useState(true);
  const [allowEditing, setAllowEditing] = React.useState(true);
  const [selectedCommunityValue, setSelectedCommunityValue] = useState<any>(initialCommunityData);
  const [selectedModel, setSelectedModel] = useState(groupData.data?.model || '');
  const [disableModelChange, setDisableModelChange] = useState(false);
  const [initialModelLoaded, setInitialModelLoaded] = useState(false);
  const isMentorFieldLocked = selectedModel === 'masterclass';
  const isMentorship = selectedModel === 'mentorship';
  const handleCurrencyChange = (value: string | null | undefined = '') => {
    setSelectedCurrency(value ?? '');
  };

  const yupFieldRequirements = Yup.object({
    name: Yup.string()
      .required('Program Name is required')
      .test('no-empty-spaces', 'Program Name cannot be all empty spaces', (value) => {
        if (value) {
          return !/^[\s]+$/.test(value);
        }
        return true;
      })
      .min(3, 'Program Name must be at least 3 characters')
      .max(75, 'Maximum 75 characters'),
    endDate: Yup.date()
      .nullable()
      .min(new Date(new Date().setDate(new Date().getDate() - 1)), 'Event date must be in the future or today')
      .transform((value, originalValue) => {
        if (originalValue === '') {
          return null;
        }
        return value;
      })
      .typeError('End date must be a valid date'),
    logo: Yup.mixed()
      .test('fileValidation', '', (value, { createError }) => {
        if (!value || !(value instanceof File)) {
          return true;
        }
        if (value.size > 5 * 1024 * 1024) {
          return createError({ message: 'File size must not exceed 5MB' });
        }
        if (!/\.(jpg|jpeg|png|svg|gif)$/i.test(value.name)) {
          return createError({
            message: 'Only jpg, png, svg, and gif files are allowed'
          });
        }
        return true;
      })
      .nullable(),
    maxMatchesPerMentee: Yup.number()
      .required('Maximum matches per ' + menteeRoleTextLowerCase + ' required')
      .min(1, 'Max matches per ' + menteeRoleTextLowerCase + ' should not be less than 1')
      .transform((value, originalValue) => {
        if (originalValue === '') {
          return null;
        }
        return value;
      })
      .typeError('Please Enter a Number'),
    maxMatchesPerMentor: Yup.number()
      .required('Maximum matches per ' + mentorRoleTextLowerCase + ' required')
      .min(1, 'Max matches per ' + mentorRoleTextLowerCase + ' should not be less than 1')
      .transform((value, originalValue) => {
        if (originalValue === '') {
          return null;
        }
        return value;
      })
      .typeError('Please Enter a Number'),

    mentee: Yup.mixed()
      .nullable()
      .test({
        message: 'This field is required',
        test: (value) => {
          const menteeValues: any = getValues('mentee');
          const menteeValuesArray = typeof menteeValues === 'string' ? [menteeValues] : menteeValues;
          return Array.isArray(menteeValuesArray) && menteeValuesArray.length > 0;
        }
      }),
    mentor: Yup.mixed()
      .nullable()
      .test({
        message: 'This field is required',
        test: (value) => {
          const mentorValues: any = getValues('mentor');
          const mentorValuesArray = typeof mentorValues === 'string' ? [mentorValues] : mentorValues;
          return Array.isArray(mentorValuesArray) && mentorValuesArray.length > 0;
        }
      }),
    topics: Yup.array()
      .min(1, 'Please select at least one topic')
      .test('valid-topics', 'Please select topics', function (value) {
        const topicsToValidate = value || this.options.context?.selectedTopicsValue || [];

        return (
          Array.isArray(topicsToValidate) &&
          topicsToValidate.length > 0 &&
          topicsToValidate.every((topic) => typeof topic === 'string' && topic.trim() !== '')
        );
      }),
    industries: Yup.array()
      .min(1, 'Please select at least one industry')
      .test('valid-industries', 'Please select industries', function (value) {
        const industriesToValidate = value || this.options.context?.selectedIndustriesValue || []; // Fallback to empty array

        return (
          Array.isArray(industriesToValidate) &&
          industriesToValidate.length > 0 &&
          industriesToValidate.every((industry) => typeof industry === 'string' && industry.trim() !== '')
        );
      }),
    // ['masterclass', 'masterclass&mentorship', 'mentorship'].includes(value || '')}
    model: Yup.string().required('Model is required'),
    // .oneOf(['masterclass', 'masterclass&mentorship', 'mentorship'], 'Invalid model value'),
    learningObjectives: Yup.object()
      .nullable()
      .shape({
        number: Yup.number()
          .required('Number of objectives is required')
          .typeError('Please enter a valid number'),
        objectives: Yup.array()
          .of(Yup.string().required('Objective cannot be empty'))
          .test(
            'min-objectives',
            `You must add at least ${learningObjectivesData.number} valid objectives`,
            function (objectives = []) {
              const validObjectives = learningObjectivesData.objectives.filter(obj => obj && obj.trim() !== '');
              return validObjectives.length >= learningObjectivesData.number;
            }
          )
      })
  });

  const {
    control,
    formState: { errors },
    reset,
    getValues,
    trigger,
    handleSubmit
  } = useForm({
    // defaultValues: {
    //   model: ''
    // },
    resolver: yupResolver(yupFieldRequirements),
    context: { selectedTopicsValue, selectedIndustriesValue, learningObjectivesData }
  });

  const updateMemberValue = () => {
    const changedRoles = {
      mentee: getValues('mentee'),
      admin: 'admin',
      mentor: getValues('mentor')
    };
    setGroupDisplayRoles(changedRoles);
  };

  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;
  const { isSettingsAllowed } = useProfileDetails();
  const { refetch: fetchGroupDetails } = useGroupDetails(grpId, locValue);
  const initApi = () => {
    fetchGroupDetails().then((response: any) => {
      dispatch(
        breadCrumbsActions.updateBreadCrumbName({
          id: grpId,
          name: response.data.data.name || getValues('name')
        })
      );
      dispatch(groupActions.updateMainGroupName(response.data.data.name || getValues('name')));
    });
  };

  /** Masterclass Model */
  // const handleMasterclassModelChange = (newValue: string) => {
  //   setSelectedValue(newValue); // Update the state with the new value
  //   localStorage.setItem('masterclassModal',newValue);
  // };

  // useEffect(() => {
  //   if (groupData.data) {
  //     const fields = groupData.data.optionalFields;
  //     const userOptionalFields = groupData.data.userOptionalFields;
  //     const fieldKeys = Object.keys(userOptionalFields || {});
  //     const updatedValues = initialValues.map((each) => {
  //       if (fieldKeys.includes(each.name)) {
  //         const permissions = userOptionalFields[each.name];
  //         const allPermissionsTrue = Object.values(permissions).every((val) => val);
  //         const updatedPermissions = {
  //           mentor: permissions.mentor === false ? true : permissions.mentor === undefined ? false : permissions.mentor,
  //           mentee: permissions.mentee === false ? true : permissions.mentee === undefined ? false : permissions.mentee,
  //           admin: permissions.admin === false ? true : permissions.admin === undefined ? false : permissions.admin
  //         };
  //         return {
  //           ...each,
  //           mentor: updatedPermissions.mentor,
  //           mentee: updatedPermissions.mentee,
  //           admin: updatedPermissions.admin,
  //           mandatory: allPermissionsTrue // If all permissions are true, make mandatory false
  //         };
  //       } else {
  //         return {
  //           ...each,
  //           mentor: false,
  //           mentee: false,
  //           admin: false,
  //           mandatory: false
  //         };
  //       }
  //     });
  //     setFieldValues(updatedValues);
  //   }
  // }, [groupData.data, initialValues, setFieldValues]);

  useEffect(() => {
    if (groupData.data) {
      const fields = groupData.data.optionalFields;
      const userOptionalFields = groupData.data.userOptionalFields;
      const fieldKeys = Object.keys(userOptionalFields || {});
      const updatedValues = initialValues.map((each) => {
        if (each.name === 'learningObjectives') {
          if (groupData.data?.model === 'mentorship') {
            setOptionalFieldsUpdateFlag(true);
            return {
              ...each,
              mentor: true,
              mentee: true,
              admin: true,
              mandatory: true
            };
          } else if (groupData.data?.model === 'masterclass' || groupData.data?.model === 'masterclass&mentorship') {
            setOptionalFieldsUpdateFlag(true);
            return {
              ...each,
              mentor: false,
              mentee: false,
              admin: false,
              mandatory: false
            };
          }
        }

        // if (each.name === 'headshot') {
        //   if (
        //     groupData.data?.model === 'mentorship' ||
        //     groupData.data?.model === 'masterclass' ||
        //     groupData.data?.model === 'masterclass&mentorship'
        //   ) {
        //     return {
        //       ...each,
        //       mentor: true,
        //       mentee: true,
        //       admin: true
        //       // Mandatory remains unchanged
        //     };
        //   }
        // }

        if (each.name === 'subjects') {
          if (groupData.data?.model === 'mentorship') {
            setOptionalFieldsUpdateFlag(true);
            return {
              ...each,
              mentor: true,
              mentee: true,
              admin: true,
              mandatory: true
            };
          } else if (groupData.data?.model === 'masterclass') {
            setOptionalFieldsUpdateFlag(true);
            return {
              ...each,
              mentor: false,
              mentee: false,
              admin: false,
              mandatory: false
            };
          }
        }

        if (fieldKeys.includes(each.name)) {
          const permissions = userOptionalFields[each.name];
          const allPermissionsTrue = Object.values(permissions).every((val) => val);
          const updatedPermissions = {
            mentor: permissions.mentor === false ? true : permissions.mentor === undefined ? false : permissions.mentor,
            mentee: permissions.mentee === false ? true : permissions.mentee === undefined ? false : permissions.mentee,
            admin: permissions.admin === false ? true : permissions.admin === undefined ? false : permissions.admin
          };
          return {
            ...each,
            mentor: updatedPermissions.mentor,
            mentee: updatedPermissions.mentee,
            admin: updatedPermissions.admin,
            mandatory: allPermissionsTrue
          };
        } else {
          return {
            ...each,
            mentor: false,
            mentee: false,
            admin: false,
            mandatory: false
          };
        }
      });
      setFieldValues(updatedValues);
    }
  }, [groupData.data, initialValues, setFieldValues]);

  useEffect(() => {
    if (groupData.data && !initialModelLoaded) {
      const currentModel = groupData.data.model || '';
      setSelectedModel(currentModel);
      if (currentModel && currentModel !== 'masterclass') {
        setDisableModelChange(true);
      }
      
      setInitialModelLoaded(true);
    }
  }, [groupData.data, initialModelLoaded]);
  

  const updateBasicSettings = async () => {
    if (basicUpdateFlag) {
      try {
        let data: any = {
          endDate: getValues('endDate'),
          name: getValues('name'),
          logo: tempImage ? tempImage : getValues('logo'),
          includeTask: includeValue || false,
          allowEditing: allowEditing || false,
          model: getValues('model')
        };

        // if (!isSettingsAllowed) {
        data = { ...data, mentee: getValues('mentee'), mentor: getValues('mentor') };
        // }

        data.endDate = data.endDate ? moment(data.endDate).format('yyyy-MM-DD') : '';
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        const response = await API.updateGroupInfo({
          orgId,
          grpId,
          formData
        });
        
        const currentModel = getValues('model');
        if (currentModel !== 'masterclass') {
          setDisableModelChange(true);
        }
        
        return response;
      } catch (e: any) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const updateOptionalFields = async () => {
    if (optionalFieldsUpdateFlag) {
      let payload: { [key: string]: { [key: string]: boolean | undefined } } = {};
      fieldValues.forEach((each) => {
        payload[each.name] = {};
        if (each.name === 'learningObjectives') {
          if (selectedModel === 'mentorship') {
            payload[each.name] = {
              mentor: true,
              mentee: true,
              admin: true
            };
          } else if (selectedModel === 'masterclass' || selectedModel === 'masterclass&mentorship') {
            payload[each.name] = {
              mentor: undefined,
              mentee: undefined,
              admin: undefined
            };
          }
        }
        // Special handling for Subject
        else if (each.name === 'subjects') {
          if (selectedModel === 'mentorship') {
            payload[each.name] = {
              mentor: true,
              mentee: true,
              admin: true
            };
          } else if (selectedModel === 'masterclass') {
            payload[each.name] = {
              mentor: undefined,
              mentee: undefined,
              admin: undefined
            };
          }
        }
        // Normal handling for other fields
        else {
          if (each.mandatory) {
            if (each.mentee) payload[each.name].mentee = true;
            if (each.admin) payload[each.name].admin = true;
            if (each.mentor) payload[each.name].mentor = true;
          } else {
            if (each.mentee) payload[each.name].mentee = false;
            if (each.admin) payload[each.name].admin = false;
            if (each.mentor) payload[each.name].mentor = false;
          }
        }
      });

      try {
        return await API.updateOptionalFields({
          grpId,
          data: payload
        });
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const updateMatchSettings = async () => {
    if (matchUpdateFlag) {
      try {
        const { maxMatchesPerMentee = 1, maxMatchesPerMentor = 1, sessionsPerMatch = 1 } = getValues();
        return await API.updateAllMatches({
          grpId,
          orgId,
          data: { maxMatchesPerMentee, maxMatchesPerMentor, matchRequest, sessionsPerMatch }
        });
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const fetchLearningObjectives = async () => {
    try {
      const response = await API.getLearningObjectives({ grpId, orgId });
      if (response.status === 200) {
        const { display, number, objectives } = response.data.data.learningObjectives || {};
        // setAllowCustomLearningObjects(display ?? false);
        // setCustomLearningObjectiveCount(number ?? 4);
        setLearningObjectivesData(
          (objectives ?? []).map((obj: { objective: string; id: string }, index: number) => ({
            _id: `id-${index + 1}`,
            title: `Learning Objective Option ${index + 1}`,
            content: obj.objective,
            objectiveId: obj.id,
            isNew: false
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching learning objectives:', error);
    }
  };

  const updateTopicSettings = async () => {
    if (topicsUpdateFlag) {
      try {
        // const { maxMatchesPerMentee = } = getValues();
        return await API.updateTopicsAndIndustry({
          grpId,
          orgId,
          data: { topics: selectedTopicsValue },
          key: 'topics'
        });
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const updateIndustrySettings = async () => {
    if (industryUpdateFlag) {
      try {
        return await API.updateTopicsAndIndustry({
          grpId,
          orgId,
          data: { industries: selectedIndustriesValue },
          key: 'industries'
        });
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const updateLearningObjectives = async () => {
    if (learningObjectiveUpdateFlag && selectedModel != 'masterclass') {
      try {
        await API.updateLearningObjectives({
          grpId,
          orgId,
          data: learningObjectivesData
        });
      } catch (error) {
        console.error('Error updating learning objectives:', error);
      }
    }
  };

  const updatebasicSettingsCommunity = async () => {
    try {
      // const { maxMatchesPerMentee = } = getValues();
      return await API.basicSettingsCommunity({
        grpId,
        orgId,
        data: selectedCommunityValue
      });
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
    }
  };

  const updateSettings = async () => {
    try {
      const response = await API.updateGroupSettings({ groupId: grpId, location: locValue });
      if (response.status === 200 && response.statusText === 'OK') {
        initApi();
        setImageUploaded(false);
        setTempImage(null);
        setSaveLoader(false);
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Settings failed to update`));
      setSaveLoader(false);
    }
  };

  const upadteAllApis = async () => {
    try {
      if (isMentorship && (!learningObjectivesData.objectives || learningObjectivesData.objectives.length === 0)) {
        toast.error('Select at least one objective');
        setSaveLoader(false);
        return;
      }
      await Promise.all([
        updateBasicSettings(),
        updateOptionalFields(),
        updateMatchSettings(),
        updateTopicSettings(),
        updateIndustrySettings(),
        updateLearningObjectives()
        // updatebasicSettingsCommunity()
      ]);
      fetchLearningObjectives();
    } catch (e: any) {
      toast.error('Something went wrong, please try again');
    } finally {
      console.log(groupData.data?.settingsUpdate, 'groupData.data?.settingsUpdate');
      if (!groupData.data?.settingsUpdate) {
        updateSettings();
      } else {
        initApi();
        setImageUploaded(false);
        setTempImage(null);
        setSaveLoader(false);
      }
    }
  };

  useEffect(() => {
    if (groupData?.data && topics.length > 0) {
      const initialSelectedTopics = groupData?.data?.subjects?.topics || [];
      setSelectedTopicsValue(initialSelectedTopics);
    }
  }, [groupData?.data, topics]);

  useEffect(() => {
    if (groupData?.data && industries.length > 0) {
      const initialSelectedIndustries = groupData?.data?.subjects?.industries || [];
      setSelectedIndustriesValue(initialSelectedIndustries);
    }
  }, [groupData?.data, industries]);

  useEffect(() => {
    if (groupData.data) {
      setSelectedCommunityValue(groupData?.data?.community);
    }
  }, [groupData?.data?.community]);

  useEffect(() => {
    if (groupData.data) {
      setAllowEditing(groupData.data.allowEditing);
      setIncludeValue(groupData.data.includeTask);
    }
  }, [groupData?.data]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setSaveLoader(true);
    upadteAllApis();
  };

  useEffect(() => {
    if (groupData?.data) {
      const { name, logo, endDate, currency, displayRoles } = groupData.data;
      const formattedEndDate = endDate ? moment.unix(endDate).format('yyyy-MM-DD') : '';
      const {
        gender = false,
        country = false,
        firstGenerationStudent = false,
        dob = false,
        ethnicity = false,
        disabilityType = false
      } = groupData.data.optionalFields || {};

      const {
        maxMatchesPerMentee = 1,
        maxMatchesPerMentor = 1,
        sessionsPerMatch = 1
      } = groupData?.data?.matchesSettings || {
        maxMatchesPerMentee: 1,
        maxMatchesPerMentor: 1,
        sessionsPerMatch: 1
      };

      const initialData = {
        name,
        logo,
        endDate: formattedEndDate,
        currency,
        mentee: displayRoles.mentee,
        mentor: displayRoles.mentor,
        gender,
        country,
        firstGenerationStudent,
        dob,
        ethnicity,
        disabilityType,
        maxMatchesPerMentee,
        maxMatchesPerMentor,
        sessionsPerMatch,
        model: groupData.data.model
      };
      reset(initialData);
      setGroupDisplayRoles(groupData?.data?.displayRoles);
    }
  }, [groupData?.data]);

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>, fieldOnChange: (file: File | null) => void) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];
      fieldOnChange(droppedFile);
      event.dataTransfer.clearData();
    }
  };
  const imageValue = getValues('logo');
  const [matchRequest, setMatchRequest] = useState(groupData.data?.matchesSettings.matchRequest);

  useEffect(() => {
    setMatchRequest(groupData.data?.matchesSettings.matchRequest);
  }, []);

  /** Handle Logo set */
  const handleImageChange = async (event: any) => {
    const file = event.target.files?.[0];
    const maxSize = imgSize.limitValue;

    if (file && file.size > maxSize) {
      toast.error('Only png, jpeg, svg, and gif files of less than 10MB can be uploaded');
      return;
    }

    if (file) {
      const allowedExtensions = ['png', 'jpeg', 'jpg', 'svg', 'gif'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        toast.error('Only jpeg, png, svg, and gif files can be uploaded');
        return;
      }
    }

    if (file) {
      setImageUploaded(true);
      try {
        let photo = file;
        const allowedExtensions = ['png', 'jpeg', 'jpg'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          photo = await handleImageCompression(file);
        }
        setTempImage(photo);
        setInputFields({ ...inputFields, logo: !logo });
        setBasicUpdateFlag(true);
      } catch (error) {
        console.error('Error handling image:', error);
      }
    } else {
      setImageUploaded(false);
    }
  };

  /** General section */
  const getGeneral = ({ locked = false } = {}): any => {
    const lockOpacity = locked ? styles.boxDisabledOpacity : '';
    return (
      <Box>
        <Box display="flex" gap={0.5} alignItems="center" mb={2}>
          <Typography variant="h5">
            <span className={lockOpacity}>General</span>{' '}
            {locked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
          </Typography>
        </Box>
        <Grid container spacing={2} className={lockOpacity}>
          <Grid item xs={12} lg={4}>
            <InputLabel>Program Name</InputLabel>
            <Controller
              name="name"
              control={control}
              disabled
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled={locked}
                  variant="outlined"
                  required
                  type="text"
                  name="name"
                  onChange={(e) => {
                    onChange(e.target.value);
                    trigger('name');
                    setBasicUpdateFlag(true);
                  }}
                  value={value || ''}
                  id="programName"
                  onBlur={() => {
                    trigger('name');
                    if (!checkError('name')) {
                      setInputFields({ ...inputFields, nam: !nam });
                    }
                  }}
                  error={checkError('name')}
                  helperText={getError('name')?.toString()}
                  placeholder="John"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <InputLabel>End Date</InputLabel>
            <Controller
              name="endDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Controller
                  name="noEndDate" // Access the value of "noEndDate" checkbox
                  control={control}
                  render={({ field: { value: noEndDate } }) => (
                    <TextField
                      type="date"
                      name="endDate"
                      placeholder="Select Date"
                      onChange={(e) => {
                        onChange(e.target.value);
                        trigger('endDate');
                        setBasicUpdateFlag(true);
                      }}
                      error={checkError('endDate')}
                      helperText={getError('endDate')?.toString()}
                      onBlur={() => {
                        trigger('endDate');
                        if (!checkError('endDate')) {
                          setInputFields({ ...inputFields, end: !end });
                        }
                      }}
                      value={value || ''}
                      id="endDate"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled={noEndDate || locked}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4} display="flex" alignItems="center" mt={{ md: 2.5 }}>
            <Controller
              name="noEndDate"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => {
                const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e.target.checked); // Update Controller state
                  setBasicUpdateFlag?.(true); // Update the flag if defined
                };

                return (
                  <FormControlLabel
                    required
                    disabled={locked}
                    checked={value}
                    label="There is no end date"
                    control={<Checkbox onChange={handleChange} />}
                  />
                );
              }}
            />
          </Grid> */}
        </Grid>
        {/* Upload logo */}
        <Box mt={2}>
          <LogoSetting
            locked={locked}
            groupData={groupData}
            imageUploaded={imageUploaded}
            tempImage={tempImage}
            imageValue={imageValue}
            handleImageChange={handleImageChange}
          />
        </Box>
      </Box>
    );
  };

  /** Topic section */
  // const getTopics = (locked?: boolean) => {
  //   const lockOpacity = locked ? styles.boxDisabledOpacity : '';
  //   return (
  //     <Box>
  //       <Box display="flex" gap="10px">
  //         <Typography variant="h5" mb={2}>
  //           <span className={lockOpacity}>Topics</span>{' '}
  //           {locked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
  //         </Typography>
  //         <FormHelperText error>{getError('topics')?.toString()}</FormHelperText>
  //       </Box>
  //       <Grid container spacing={2} className={lockOpacity}>
  //         {_.sortBy(topics, 'label').map((each, index: any) => {
  //           return (
  //             <Controller
  //               key={index}
  //               name={each.name}
  //               control={control}
  //               render={({ field: { onChange, value } }) => {
  //                 return (
  //                   <Grid item xs={12} md={6} display="flex" alignItems="center" gap="8px">
  //                     <Switch
  //                       // {...label}
  //                       checked={selectedTopicsValue[each.name] || false}
  //                       onChange={(e) => {
  //                         onChange(e.target.checked);
  //                         setOptional(!optional);
  //                         // Update the selectedTopicsValue based on the switch state
  //                         setSelectedTopicsValue((prev: any) => ({
  //                           ...prev,
  //                           [each.name]: e.target.checked
  //                         }));
  //                         setTopicsUpdateFlag(true);
  //                       }}
  //                       name={each.name}
  //                     />
  //                     <Typography color="primary" noWrap>
  //                       {each.label}
  //                     </Typography>
  //                   </Grid>
  //                 );
  //               }}
  //             />
  //           );
  //         })}
  //       </Grid>
  //     </Box>
  //   );
  // };

  /** Industries section */
  // const getIndustries = (locked?: boolean) => {
  //   const lockOpacity = locked ? styles.boxDisabledOpacity : '';
  //   return (
  //     <Box>
  //       <Box display="flex" gap="10px">
  //         <Typography variant="h5" mb={2}>
  //           <span className={lockOpacity}>Industries</span>{' '}
  //           {locked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
  //         </Typography>
  //         <FormHelperText error>{getError('industries')?.toString()}</FormHelperText>
  //       </Box>
  //       <Grid container spacing={2} className={lockOpacity}>
  //         {_.sortBy(industries, 'label').map((each, index: any) => {
  //           return (
  //             <Controller
  //               key={index}
  //               name={each.name}
  //               control={control}
  //               render={({ field: { onChange, value } }) => {
  //                 return (
  //                   <Grid item xs={12} md={6} display="flex" alignItems="center" gap="8px">
  //                     <Switch
  //                       checked={selectedIndustriesValue[each.name] || false}
  //                       onChange={(e) => {
  //                         onChange(e.target.checked);
  //                         setOptional(!optional);
  //                         // Update the selectedTopicsValue based on the switch state
  //                         setSelectedIndustriesValue((prev: any) => ({
  //                           ...prev,
  //                           [each.name]: e.target.checked
  //                         }));
  //                         setIndustryUpdateFlag(true);
  //                       }}
  //                       name={each.name}
  //                     />
  //                     <Typography color="primary" noWrap>
  //                       {each.label}
  //                     </Typography>
  //                   </Grid>
  //                 );
  //               }}
  //             />
  //           );
  //         })}
  //       </Grid>
  //     </Box>
  //   );
  // };

  /** Matches section */
  const matchesSettings = (locked?: boolean) => {
    const lockOpacity = locked ? styles.boxDisabledOpacity : '';
    return (
      <Box>
        <Box display="flex" gap={0.5} alignItems="center" mb={2}>
          <Typography variant="h5">
            <span className={lockOpacity}>Mentorship</span>{' '}
            {isMentorFieldLocked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
          </Typography>
        </Box>
        <Grid container spacing={1} className={lockOpacity} mb={1} alignItems={'center'}>
          <Grid item xs={9} md={6} lg={5} xl={4}>
            <Typography>
              <span>Maximum matches per {menteeRoleTextLowerCase} *</span>
              {isMentorFieldLocked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
            </Typography>
          </Grid>
          <Grid item xs={3} md={2} lg={1}>
            <Controller
              control={control}
              name="maxMatchesPerMentee"
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  disabled={isMentorFieldLocked}
                  onBlur={() => {
                    trigger('maxMatchesPerMentee');
                    if (!checkError('maxMatchesPerMentee')) {
                      setInputFields({ ...inputFields, matchPerMentee: !matchPerMentee });
                    }
                  }}
                  type="number"
                  placeholder="0"
                  variant="outlined"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setMatchUpdateFlag(true);
                    const inputValue = e.target.value.trim() === '' ? '' : parseInt(e.target.value);
                    onChange(inputValue);
                    trigger('maxMatchesPerMentee');
                  }}
                  name="maxMatchesPerMentee"
                  value={value || ''}
                  id="maxMatchesPerMentee"
                  error={checkError('maxMatchesPerMentee')}
                  helperText={getError('maxMatchesPerMentee')?.toString()}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} className={lockOpacity} mb={1} alignItems={'center'}>
          <Grid item xs={9} md={6} lg={5} xl={4}>
            <Typography>
              <span>Maximum matches per {mentorRoleTextLowerCase} *</span>
              {isMentorFieldLocked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
            </Typography>
          </Grid>
          <Grid item xs={3} md={2} lg={1}>
            <Controller
              control={control}
              name="maxMatchesPerMentor"
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  disabled={isMentorFieldLocked}
                  onBlur={() => {
                    trigger('maxMatchesPerMentor');
                    if (!checkError('maxMatchesPerMentor')) {
                      setInputFields({ ...inputFields, matchPerMentor: !matchPerMentor });
                    }
                  }}
                  type="number"
                  placeholder="0"
                  variant="outlined"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setMatchUpdateFlag(true);
                    const inputValue = e.target.value.trim() === '' ? '' : parseInt(e.target.value);
                    onChange(inputValue);
                    trigger('maxMatchesPerMentor');
                  }}
                  value={value || ''}
                  id="maxMatchesPerMentor"
                  name="maxMatchesPerMentor"
                  error={checkError('maxMatchesPerMentor')}
                  helperText={getError('maxMatchesPerMentor')?.toString()}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} className={lockOpacity} mb={1} alignItems={'center'}>
          <Grid item xs={9} md={6} lg={5} xl={4}>
            <Typography>
              <span>Required mentorship sessions per match</span>
              {isMentorFieldLocked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
            </Typography>
          </Grid>
          <Grid item xs={3} md={2} lg={1}>
            <Controller
              control={control}
              name="sessionsPerMatch"
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  disabled={isMentorFieldLocked}
                  onBlur={() => {
                    trigger('sessionsPerMatch');
                    if (!checkError('sessionsPerMatch')) {
                      setInputFields({ ...inputFields, sessionsPerMatch: !sessionsPerMatch });
                    }
                  }}
                  type="number"
                  placeholder="0"
                  variant="outlined"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setMatchUpdateFlag(true);
                    const inputValue = e.target.value.trim() === '' ? '' : parseInt(e.target.value);
                    onChange(inputValue);
                    trigger('sessionsPerMatch');
                  }}
                  value={value || ''}
                  id="sessionsPerMatch"
                  name="sessionsPerMatch"
                  error={checkError('sessionsPerMatch')}
                  helperText={getError('sessionsPerMatch')?.toString()}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={1} className={lockOpacity}>
          {/* Members 'Request a Match' */}
          <Grid item xs={9} md={6} lg={5} xl={4}>
            <Typography>
              <span>Allow members to 'Request a Match' </span>
              {isMentorFieldLocked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
            </Typography>
          </Grid>
          <Grid item xs={3} md={2} lg={1}>
            <Switch
              {...label}
              checked={matchRequest}
              onChange={(e) => {
                setMatchUpdateFlag(true);
                setMatchRequest(e.target.checked);
              }}
              disabled={isMentorFieldLocked}
              id="requestMatch"
              name="requestMatch"
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  /** Loader */
  const loading = useLoader(groupData.loading, 1000);
  if (loading) {
    return <BasicSettingsLoader />;
  }

  if (groupData.error) {
    return <Box>{groupData.errorText}</Box>;
  }

  if (groupData.data) {
    return (
      <Grid container bgcolor="#FFFFFF" border="1px solid #EFF0F4" borderRadius="8px">
        <Grid item xs={12} md={12}>
          <Box borderRight="1px solid #EFF0F4">
            <Box p={2}>
              <Typography variant="h4">Basic Configurations</Typography>
            </Box>
            <Divider />
            <Box p={2}>
              {/* Masterclass Model */}
              <MasterclassModelSetting
                locked={false}
                value={selectedModel ? selectedModel : groupData.data.model || ''}
                control={control}
                getError={getError}
                setBasicUpdateFlag={setBasicUpdateFlag}
                onModelChange={(newModel) => setSelectedModel(newModel)}
                disableModelChange={disableModelChange}
              />
              <Divider className="my-20" />

              {/* General */}
              {getGeneral({ locked: false })}
              <Divider className="my-20" />

              {/* Members */}
              <Members
                control={control}
                updateMemberValue={updateMemberValue}
                setBasicUpdateFlag={setBasicUpdateFlag}
                place="BasicSettings"
                checkError={checkError}
                getError={getError}
                model={selectedModel}
              />
              <Divider className="my-20" />

              {/* Subjects */}
              <SubjectSetting
                control={control}
                topics={topics}
                industries={industries}
                selectedTopicsValue={selectedTopicsValue || []}
                selectedIndustriesValue={selectedIndustriesValue || []}
                setSelectedTopicsValue={setSelectedTopicsValue}
                setSelectedIndustriesValue={setSelectedIndustriesValue}
                setTopicsUpdateFlag={setTopicsUpdateFlag}
                setIndustryUpdateFlag={setIndustryUpdateFlag}
                setTopics={setTopics}
                setIndustries={setIndustries}
              />

              <Divider className="my-20" />

              {/* Fields */}
              <Fields
                setFieldValues={setFieldValues}
                fieldValues={fieldValues}
                setOptionalFieldsUpdateFlag={setOptionalFieldsUpdateFlag}
                groupDisplayRoles={groupDisplayRoles}
                model={selectedModel}
              />
              <Divider className="my-20" />

              {/* Learning Objectives */}
              <LearningObjectsSetting
                control={control}
                setLearningObjectivesData={setLearningObjectivesData}
                learningObjectivesData={learningObjectivesData}
                setLearningObjectiveUpdateFlag={setLearningObjectiveUpdateFlag}
                model={selectedModel}
                errors={errors}
              />
              <Divider className="my-20" />

              {/* Matches Settings */}
              {matchesSettings()}

              {/* Save Button */}
              <Box mt={2} textAlign="right">
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={saveLoader}
                  className="h-50px w-150px"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default BasicSettings;
